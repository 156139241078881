import { isGoogleTrackingEnable } from "../utils/helper"

export const pageview = (url) => {
  if (isGoogleTrackingEnable()) {
    window.gtag('config', process.env.GA_MEASUREMENT_ID, {
      page_path: url,
    })
  }
}

export const event = ({ name, category, label, value }) => {
  if (window && window.gtag && isGoogleTrackingEnable()) {
    window.gtag('event', name, {
      event_category: category,
      event_label: label,
      value: value,
    })
  }

}