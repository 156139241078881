import React from 'react'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { config } from '@fortawesome/fontawesome-svg-core'
import { useRouter } from 'next/router';
import * as fbq from '../lib/fb-pixel'
import * as gtag from '../lib/gtag'
import Script from 'next/script'
import { Partytown } from '@builder.io/partytown/react';

import 'antd/dist/antd.css';
import '@fortawesome/fontawesome-svg-core/styles.css'
import '../styles/tailwind/tailwind.css'
import '../styles/vendors/nprogress.css'
import '../styles/nvusa/main.scss';

import GlobalStateContext from '../contexts/GlobalStateProvider';
import { StoreProvider } from '../contexts/StoreContext';
import { CookieProvider } from '../contexts/CookieContext';
import { Roboto } from '@next/font/google';
import { isGoogleTrackingEnable, isPixelTrackingEnable, isProduction } from '../utils/helper';

const roboto = Roboto(
  { weight: ['300', '400', '500', '700'], style: ['normal'], subsets: ['latin'] }
)
config.autoAddCss = false


export function reportWebVitals(metric) {
  if (isProduction()) {
    const event_category = metric.label === 'web-vital' ? 'Web Vitals' : 'NextJS custom metric';
    gtag.event({
      name: metric.name,
      event_category,
      value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
      event_label: metric.id,
      non_interaction: true,
    })
  } else {
    console.log(metric)
  }
}

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    console.log(router.events);
    fbq.pageview();

    router.events.on('routeChangeStart', () => NProgress.start())

    router.events.on('routeChangeError', () => NProgress.done())

    router.events.on('routeChangeComplete', (url) => {
      NProgress.done();
      fbq.pageview();
      gtag.pageview(url)
    });

    return () => {
      router.events.off('routeChangeComplete', (url) => {
        fbq.pageview();
        gtag.pageview(url)
      })
    }
  }, [router.events])

  const resolveUrl = function (url) {
    if (url.hostname === "connect.facebook.net") {
      var proxyUrl = new URL(process.env.APP_URL.concat('/proxy/facebook-connect').concat(url.pathname).concat(url.search));
      return proxyUrl;
    }
    return url;
  }

  return (
    <GlobalStateContext>
      <StoreProvider>
        <CookieProvider cookies={{}}>
          <Partytown debug={false} forward={['dataLayer.push', 'fbq']} resolveUrl={resolveUrl} />
          {isPixelTrackingEnable() && <Script
            strategy="worker"
            dangerouslySetInnerHTML={{
              __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${fbq.FB_PIXEL_ID});
              `,
            }}
          />}

          {
            isGoogleTrackingEnable() &&
            <Script
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GA_MEASUREMENT_ID}`}
              strategy="worker"
            />
          }

          {
            isGoogleTrackingEnable() &&
            <Script
              strategy="worker"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.GA_MEASUREMENT_ID}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          }
          <main className={roboto.className} >
            <Component {...pageProps} />
          </main>
        </CookieProvider>
      </StoreProvider>
    </GlobalStateContext>
  )
}

export default MyApp