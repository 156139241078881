import axios from 'axios';
import Router from "next/router";

const extractGeoLocation = (data) => {
  if (data.lng === 0) return;

  let location = {};

  location.formatted_address = data.formatted_address;
  location.lng = data.lng.toFixed(8)
  location.lat = data.lat.toFixed(8)
  data.address_components.map(x => {
    if (x.types.includes('locality')) {
      location.city = x.long_name;
    }
    if (x.types.includes('administrative_area_level_2')) {
      location.county = x.long_name;
    }
    if (x.types.includes('administrative_area_level_1')) {
      location.state = x.short_name;
    }
    if (x.types.includes('postal_code')) {
      location.zipcode = x.long_name;
    }
  })
  return location;
}

const getGeoLocation = async (zipcode) => {
  const resp = await axios.get(`${process.env.API_URL}/api/v1/locations/get-geo-coding?address=${zipcode}`);
  const geoLocation = extractGeoLocation(resp.data);
  return geoLocation;
}

const setLocaionOnZipcodeChange = async (zipcode, setFieldValue, setFieldError, updateFields = []) => {
  const geoLocation = await getGeoLocation(zipcode);
  if (geoLocation === undefined) {
    setFieldError('zipcode', 'Invalid zipcode');
    setFieldValue('zipcode', '');
    return;
  }
  if (geoLocation) {
    updateFields.map(x => {
      if (geoLocation[x]) {
        setFieldValue(x, geoLocation[x]);
      }
    })
  }
}


const removeEmptyChildren = (parent) => {
  parent.forEach((category) => {
    if (category.children.length > 0) {
      removeEmptyChildren(category.children);
      category.isLeaf = false;
    } else {
      category.isLeaf = true;
      delete category.children
    }
  });
  return parent;
}

const findCategory = (categories, id) => {
  return categories.reduce((category, val) => category.concat(val.children), []).find(x => x.id === id);
}

const flatten = (categories) => {
  var result = [];
  categories.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flatten(a.children));
    }
  });
  return result;
}

const getCategoriesWithRequiredBusiness = (categories) => {
  return categories.reduce((category, val) => category.concat(val.children), [])
    .filter(x => x.required_business)
    .map(x => x.id);
}

const redirect = (url, ctx) => {
  if (isServerSide(ctx)) {
    ctx.res.writeHead(302, {
      Location: url
    })
    ctx.res.end()
  } else {
    Router.push(url);
  }
}

const isServerSide = (ctx) => {
  return (ctx && ctx.req && ctx.res) ? true : false;
}

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

const getClientIP = (req) => {
  const forwarded = req.headers['x-forwarded-for'];
  return typeof forwarded === 'string' ? forwarded.split(/, /)[0] : req.socket.remoteAddress;
}

const isProduction = () => process.env.NODE_ENV === 'production';

const isGoogleTrackingEnable = () => process.env.ENABLE_PIXEL_TRACKING === 'true';
const isPixelTrackingEnable = () => process.env.ENABLE_GOOGLE_TRACKING === 'true';


export {
  getGeoLocation,
  findCategory,
  flatten,
  removeEmptyChildren,
  getCategoriesWithRequiredBusiness,
  redirect,
  isServerSide,
  setLocaionOnZipcodeChange,
  formatPhoneNumber,
  getClientIP,
  isProduction,
  isGoogleTrackingEnable,
  isPixelTrackingEnable
}