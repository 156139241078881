import React, { Component } from 'react';

const GlobalStateContext = React.createContext();

const defaultLocation = {
    city: 'Dallas',
    objectID: '4684888',
    state_name: 'Texas',
    state_code: 'TX',
    slug: 'dallas-tx',
    _geoloc: {lat: 32.7763, lng: -96.7969}
}

class GlobalStateProvider extends Component {

    state = {
        isAuthenticated: null,
        isDefaultLocation: false,
        profile: {},
        location: {},
        cookies: {}
    };

    constructor( props ){
        super( props );
        this.setGlobalState = this.setGlobalState.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    }

    componentDidMount() {
        const {cookies} = this.props;
        if (cookies){
            this.setState({cookies: cookies})
            
            if(cookies.location){
                this.setState({location: JSON.parse(cookies.location)})
            }else {
                this.setState({location: defaultLocation, isDefaultLocation: true})
            }

            if(cookies.profile) {
                this.setState({profile: JSON.parse(cookies.profile)})
            }
        }
        this.setState({isAuthenticated: this.isAuthenticated})
    }

    setGlobalState(state) {
        this.setState(state);
    }

    isAuthenticated() {
        return (this.props.cookies && this.props.cookies.profile)
    }

    render() {
        return (
            <GlobalStateContext.Provider
                value={{
                    globalState: this.state,
                    setGlobalState: this.setGlobalState,
                }}
            >
                {this.props.children}
            </GlobalStateContext.Provider>
        );
    }
}

const GlobalStateConsumer = GlobalStateContext.Consumer;

export default GlobalStateProvider;
export { GlobalStateConsumer, GlobalStateContext };