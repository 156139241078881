import React, { useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import StoreContext, { initialState } from '../contexts/StoreContext';

const CookieContext = React.createContext();

export const CookieProvider = ({ cookies, children }) => {
    const { state, dispatch } = useContext(StoreContext);

    useEffect(() => {
        if (cookies) {
            if (cookies.location) {
                dispatch({ type: 'SET_LOCATION', payload: JSON.parse(cookies.location) })
            }
            if (cookies.token) {
                dispatch({ type: 'SET_AUTH_TOKEN', payload: { token: cookies.token, expiresAt: cookies.expiresAt } });
            }
            if (cookies.profile) {
                dispatch({ type: 'SET_PROFILE', payload: JSON.parse(cookies.profile) })
            }
        }
    }, []);

    return (
        <CookieContext.Provider value={cookies}>
            {children}
        </CookieContext.Provider >
    )
}

export default CookieContext;