import { isPixelTrackingEnable } from "../utils/helper";

export const FB_PIXEL_ID = '887202645321846'

export const pageview = () => {
  if (!isPixelTrackingEnable()) return;

  window.fbq('track', 'PageView')
}

export const trackCustom = (name, options = {}) => {
  if (!isPixelTrackingEnable()) return;

  window.fbq('trackCustom', name, options)
}

export const event = (name, options = {}) => {
  if (!isPixelTrackingEnable()) return;

  window.fbq('track', name, options)
}