import React, { useEffect, useReducer } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

const StoreContext = React.createContext();

export const initialState = {
    location: {
        city: 'Dallas',
        objectID: '4684888',
        state_name: 'Texas',
        state_code: 'TX',
        text: 'Dallas, TX',
        highLight: '',
        slug: 'dallas-tx',
        _geoloc: { lat: 32.7763, lng: -96.7969 }
    },
    isDefaultLocation: false,
    profile: {},
    token: '',
    expiresAt: ''
}


const storeReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOCATION':
            Cookies.set('location', JSON.stringify(action.payload));
            return { ...state, location: action.payload, isDefaultLocation: false };

        case 'REMOVE_LOCATION':
            return [...state, action.payload]

        case 'SET_AUTH_TOKEN':
            Cookies.set('token', action.payload.token);
            Cookies.set('expiresAt', action.payload.expiresAt);
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`
            return { ...state, token: action.payload.token, expiresAt: action.payload.expiresAt }

        case 'LOGOUT':
            Cookies.remove('token');
            Cookies.remove('expiresAt');
            Cookies.remove('profile');
            return { ...state, token: '', expiresAt: '', profile: {} }

        case 'SET_PROFILE':
            Cookies.set('profile', JSON.stringify(action.payload));
            return { ...state, profile: action.payload }

        default:
            break;
    }
}

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(storeReducer, initialState);

    const getWindowdemensions = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // Temporary
    useEffect(() => {
        getWindowdemensions();
        window.addEventListener('resize', () => {
            getWindowdemensions()
        });
    }, [])

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            {children}
        </StoreContext.Provider >
    )
}

export default StoreContext;